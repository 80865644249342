import { styled } from '../../../Theme/stitches.config';
import { useRef } from 'react';
import ProductCardList from 'Commerce/Molecules/ProductList/ProductCardList';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import BlockHeader from '../BlockHeader';
import LoadMoreContainer from 'DesignComponents/Molecules/LoadMoreContainer/LoadMoreContainer';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import useLoadMore from 'Shared/Hooks/useLoadMore';
import SubscriptionOfferCardModel from 'Models/SubscriptionOffer/SubscriptionOfferCardModel.interface';
import SubscriptionOffersBlockModel from 'Models/Blocks/SubscriptionOffersBlock/SubscriptionOffersBlockModel.interface';

export type SubScriptionOfferListingType = {
  content: SubscriptionOffersBlockModel;
  noPadding?: boolean;
};
function SubscriptionOffersBlock({
  content: { heading, offersCards, inEditMode, link, pageSize },
}: SubScriptionOfferListingType) {
  const rootRef = useRef<HTMLDivElement>(null);
  const { categoryLabels } = useTranslationData();
  const { list, showMore, loadMore } = useLoadMore<SubscriptionOfferCardModel>(
    offersCards,
    pageSize
  );
  return (
    <Root ref={rootRef}>
      <Header>
        <BlockHeader inEditMode={inEditMode} heading={heading} />
        {link?.text && link.href && (
          <LinkContainer>
            <InteractableContentLink
              {...applyEditModeAttr(inEditMode && 'Link')}
              href={link.href}
              Element={StyledA}
            >
              {link.text}
            </InteractableContentLink>
          </LinkContainer>
        )}
      </Header>
      <ProductCardList
        {...applyEditModeAttr(inEditMode && 'Subscription offers')}
        items={list}
      />
      {showMore && (
        <div>
          <LoadMoreContainer
            loadMore={categoryLabels.loadMore}
            isLoading={false}
            onClick={loadMore}
          />
        </div>
      )}
    </Root>
  );
}

const Root = styled('section', {
  mx: 'auto',
  my: 0,
  px: 0,
  wh: '100%',
  py: '$s200',
  '@bpMin721': {
    py: '$s300',
  },
});

const Header = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
});
const LinkContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
});
const StyledA = styled('a', {
  color: '$emphasisedLinkColor',
  fontFamily: 'fontBold',
  whiteSpace: 'nowrap',
  '&:focus-visible': {
    boxShadow: '0px 0px 0px 2px $colors$interactiveBorderActiveInverse',
  },
});
export default SubscriptionOffersBlock;
