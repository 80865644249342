import { applyEditModeAttr } from 'Shared/Common/Helpers';
import Heading from 'DesignSystem/Typography/Headings/Heading';

type BlockHeaderProps = {
  inEditMode: boolean;
  heading: string;
};

function BlockHeader({ inEditMode, heading }: BlockHeaderProps) {
  return (
    <Heading
      {...applyEditModeAttr(inEditMode && 'Heading')}
      tag="h2"
      size="l"
      css={{
        fontSize: '$fontSize200',
        fontFamily: 'fontSemiBold',
        color: '$onBlockHeader',
        lineHeight: '$lh24',
        marginBottom: '8px',
        '@bpMin721': {
          marginBottom: '16px',
          fontSize: '$fontSize500',
          lineHeight: '$lh32',
        },
      }}
    >
      {heading}
    </Heading>
  );
}

export default BlockHeader;
