import { useEffect, useState } from 'react';

type LoadMoreHookReturnType<T> = {
  list: T[];
  showMore: boolean;
  loadMore: () => void;
};

function useLoadMore<T>(
  initialItems: T[],
  pageSize: number
): LoadMoreHookReturnType<T> {
  const [list, setList] = useState<T[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    setList(pageSize > 0 ? initialItems.slice(0, pageSize) : initialItems);
    setShowMore(initialItems.length > pageSize && pageSize > 0);
    setIndex(pageSize);
  }, [initialItems, pageSize]);

  const loadMore = () => {
    const newIndex = index + pageSize;
    const newShowMore = newIndex < initialItems.length;
    const newList = list.concat(initialItems.slice(index, newIndex));
    setIndex(newIndex);
    setList(newList);
    setShowMore(newShowMore);
  };

  return { list, showMore, loadMore };
}

export default useLoadMore;
